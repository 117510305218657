.cookie-dialog-positioning {
  position: fixed;
  z-index: 1000;
  left: 2rem;
  right: 2rem;
  bottom: 15%;
  height: 60vh;
  padding: 1rem;
  scroll-snap-stop: normal;
}

.cookie-dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
  z-index: 999; /* Ensures it appears below the cookie banner but above the page content */
}

.cookie-dialog-positioning.open {
  display: block;
}

.cookie-dialog-positioning:not(.open) {
  display: none;
}

.cookie-dialog {
  overflow: hidden; 
  height: 60vh;
  text-align: left;
  padding-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: white;
  border-color: var(--activeColor);
  border-style: solid;
  border-width: 1px;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
}

.cookie-buttons {
  display: flex;
  flex-wrap: wrap; 
  gap: 0em; 
  justify-content:center;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

