.Data-fields-section {
  table td:nth-child(2) {
    /* TODO: Make it occupy the full width? */
    padding-left: 0.5rem;
  }

  .Data-fields-img-wf-placeholder {
    /* opacity: 0.7; */
    transform: translate(0.3%, 0.85%) scale(0.735);
  }

  .flying-marker-parent {
    width: 100%;
    aspect-ratio: 1;
    /* background-color: green; */
    /* opacity: 0.5; */
  }

  .flying-marker {
    --markerSize: 8cqw;

    justify-content: center;
    position: relative;

    width: var(--markerSize);
    height: var(--markerSize);
    /* background-color: red; */
  }

  .flying-marker-particle {
    width: 100%;
    height: 100%;
    position: absolute;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    box-shadow: 0 0 20px #0008;
    border-radius: 1000px;
  }

  .flying-marker-particle .inner {
    width: 100%;
    height: 100%;
    border-radius: 1000px;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  .flying-marker-particle .inner:nth-child(2) {
    animation-name: Data-fields-flying-marker-particle-opacity;
    z-index: 100;
  }

  /* ### */

  .flying-marker-particle:nth-child(1) {
    animation-name: Data-fields-flying-marker-particle-move-1;
    animation-duration: 2000ms;
  }

  .flying-marker-particle:nth-child(1) .inner {
    background-color: var(--color-orange);
  }

  .flying-marker-particle:nth-child(1) .inner:nth-child(2) {
    animation-duration: 3000ms;
  }

  /* ### */

  .flying-marker-particle:nth-child(2) {
    animation-name: Data-fields-flying-marker-particle-move-2;
    animation-duration: 2500ms;
  }

  .flying-marker-particle:nth-child(2) .inner {
    background-color: var(--color-rose);
  }

  .flying-marker-particle:nth-child(2) .inner:nth-child(2) {
    animation-duration: 4000ms;
  }

  /* ### */

  .flying-marker-particle:nth-child(3) {
    animation-name: Data-fields-flying-marker-particle-move-3;
    animation-duration: 2200ms;
  }

  .flying-marker-particle:nth-child(3) .inner {
    background-color: var(--activeColor);
  }

  .flying-marker-particle:nth-child(3) .inner:nth-child(2) {
    animation-duration: 2800ms;
  }

  .Data-fields-sparkles {
    --sprite-size: 13cqw;
    mask-size: var(--sprite-size) var(--sprite-size);
    height: var(--sprite-size);
    width: var(--sprite-size);
    scale: 0;
    opacity: 0;
  }

  .Data-fields-sparkles-heart_rate {
    mask-image: url(../../assets/df_particle_cloud/heart_rate.png);
    background-color: var(--color-red);
  }

  .Data-fields-sparkles-distance_walked {
    mask-image: url(../../assets/df_particle_cloud/distance_walked.png);
    background-color: var(--color-acid-green);
  }

  .Data-fields-sparkles-steps {
    mask-image: url(../../assets/df_particle_cloud/steps.png);
    background-color: var(--color-magenta);
  }

  .Data-fields-sparkles-weekly_run_distance {
    mask-image: url(../../assets/df_particle_cloud/weekly_run_distance.png);
    background-color: var(--color-acid-green);
  }

  .Data-fields-sparkles-calories {
    mask-image: url(../../assets/df_particle_cloud/calories.png);
    background-color: var(--color-orange);
  }

  .Data-fields-sparkles-notification_count {
    mask-image: url(../../assets/df_particle_cloud/notification_count.png);
    background-color: var(--color-yellow);
  }

  .Data-fields-sparkles-current_temperature {
    mask-image: url(../../assets/df_particle_cloud/current_temperature.png);
    background-color: var(--color-orange);
  }

  .Data-fields-sparkles-recovery_time {
    mask-image: url(../../assets/df_particle_cloud/recovery_time.png);
    background-color: var(--color-blue);
  }
}

/* Can't use "transform" in these animations because it would create 
   a new stacking context and break the z-index/opacity trick
 */
@keyframes Data-fields-flying-marker-particle-move-1 { 
  from { left: 1cqw; top: 0cqw; }
  25% { left: -0.5cqw; top: 1cqw; }
  50% { left: -1cqw; top: 0cqw; }
  25% { left: 0cqw; top: 0.5cqw; }
  to { left: 1cqw; top: 0cqw; }
}

@keyframes Data-fields-flying-marker-particle-move-2 { 
  from { left: 0cqw; top: 1cqw; }
  25% { left: 1cqw; top: -0.75cqw; }
  50% { left: 0cqw; top: -1cqw; }
  75% { left: -0.8cqw; top: -0.25cqw; }
  to { left: 0cqw; top: 1cqw; }
}

@keyframes Data-fields-flying-marker-particle-move-3 { 
  from { left: -0.8cqw; top: 0.4cqw; }
  25% { left: 0cqw; top: -0.5cqw; }
  60% { left: 0.7cqw; top: 1cqw; }
  75% { left: 0.5cqw; top: 0.8cqw; }
  to { left: -0.8cqw; top: 0.4cqw; }
}

@keyframes Data-fields-flying-marker-particle-opacity { 
  from { opacity: 0; }
  50% { opacity: 1; }
  to { opacity: 0; }
}
