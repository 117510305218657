@media (max-aspect-ratio: 1) {
  .section-with-image.Section-install {
    grid-template-rows: auto auto 1rem 1fr;
    grid-template-areas:
      "title"
      "text"
      "."
      "image";
    margin-bottom: 1rem;
  }
}

.Section-install {
  /* min-height: calc(100vh - var(--header-height) - var(--footer-height)); */

  .icon-list {
    --bullet-icon-size: 2.5rem;
    list-style-type: none;
    padding: 0;
  }
  
  .icon-list li {
    display: grid;
    grid-template-columns: var(--bullet-icon-size) 1fr;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .icon-list li::before {
    display: flex;

    content: '';
    mask-size: var(--bullet-icon-size) var(--bullet-icon-size);
    width: var(--bullet-icon-size);
    height: var(--bullet-icon-size);
    
    background-color: #555;
  }

  .icon-list li:nth-child(1)::before {
    mask-image: url(../../assets/imgs_128px/always_on_black.png);
  }

  .icon-list li:nth-child(2)::before {
    mask-image: url(../../assets/imgs_128px/battery_black.png);
  }
}