.Photo-gallery-section {
  .section-image-cell {
    margin-left: 0;
    margin-right: 0;
  }
}

.Photo-gallery {
  --gap: calc(2 * var(--section-padding));
  --photo-size: calc(100cqw - var(--gap));
  --radius: 40px;
  --inner-radius: calc(var(--radius) - 10px);

  position: relative;
  overflow-y: hidden;
  white-space: nowrap;

  width: 100cqw;
  height: var(--photo-size);

  box-sizing: border-box;
}

.App:not(.no-auto-animation) .Photo-gallery {
  overflow-x: hidden;
}

.App.no-auto-animation .Photo-gallery {
  overflow-x: scroll;
  scroll-snap-stop: normal;
  scroll-snap-type: x mandatory;
}

.App.no-auto-animation .Photo-container {
  scroll-snap-align: center;
}

@media (max-aspect-ratio: 1) {
    .App.no-auto-animation .Photo-gallery {
    /* Some space to hide the progress bar */
    /* TODO: reimplement properly */
    height: calc(var(--photo-size) + 4px);
    padding-bottom: 4px;
  }

  .App.no-auto-animation .Photo-gallery::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  .App.no-auto-animation .Photo-gallery:horizontal {
    height: 0px;
  }

  .App.no-auto-animation .Photo-gallery::-webkit-scrollbar-thumb {
    border: 0px solid white;
  }
}


@media (min-aspect-ratio: 1) {
  .Photo-gallery-section .section-image-cell {
    aspect-ratio: 1;
  }

  .Photo-gallery {
    --gap: 10cqw;
    --photo-size: 100cqw;

    margin-top: 0;
    margin-bottom: 0;
  }
}


.Photo-gallery-internal {
  height: var(--photo-size);

  display: inline-flex;
  flex-direction: row;
}

.Photo-container {
  height: var(--photo-size);
  aspect-ratio: 1;

  position: relative;
  left: calc(50cqw - var(--photo-size) / 2);

  margin-right: var(--gap);

  display: inline-block;
  overflow: clip;
  border-radius: var(--inner-radius);
  /* clip-path: inset(0% 0% 0% 0% round var(--inner-radius)); */
}

.Photo-container-internal {
  background-color: black;
  aspect-ratio: 1;
}

.App.no-auto-animation  .Photo-container-internal {
  transition: transform .4s ease
}

.App:not(.no-auto-animation) .Photo-round-button {
  display: none;
}

.App.no-auto-animation .Photo-round-button {
  --size: 4rem;

  position: absolute;

  width: var(--size);
  aspect-ratio: 1;

  margin: unset;

  background-color: white;
  border-radius: calc(var(--size) / 2);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.App.no-auto-animation .Photo-round-button:active {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.App.no-auto-animation .Photo-round-button::after {
  --iconSize: calc(var(--size) / 2);

  content: '';
  width: var(--iconSize);
  aspect-ratio: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(var(--iconRotation));
  background-color: black;
  mask-image: url(../../assets/imgs_128px/caret.png);
  mask-size: var(--iconSize) var(--iconSize);
}

.Photo-round-button:disabled {
  background-color: #aaa;
  transition: background-color .2s ease;
}

.Photo-round-button:disabled::after {
  background-color: #555;
}

.App.no-auto-animation .Photo-next-button {
  --iconRotation: -90deg;
  top: 50%;
  translate: 0 -50%;
}

.App.no-auto-animation .Photo-prev-button {
  --iconRotation: 90deg;
  top: 50%;
  translate: 0 -50%;
}

@media (min-aspect-ratio: 1) {
  /* TODO: reimplement these properly */
  .App.no-auto-animation .Photo-next-button {
    right: 5%;
  }

  .App.no-auto-animation .Photo-prev-button {
    left: 5%;
  }
}

@media (max-aspect-ratio: 1) {
  .App.no-auto-animation .Photo-next-button {
    right: 0;
  }

  .App.no-auto-animation .Photo-prev-button {
    left: 0;
  }
}

.App.no-auto-animation .Photo-zoom-button {
  --size: 3rem;
  --iconRotation: 0deg;
  right: 5%;
  bottom: 5%;
}

.App.no-auto-animation .Photo-zoom-button.zoomed-in::after {
  mask-image: url(../../assets/imgs_128px/minus.png);
}

.App.no-auto-animation .Photo-zoom-button:not(zoomed-in)::after {
  mask-image: url(../../assets/imgs_128px/plus.png);
}

.Photo-image {
  height: var(--photo-size);
  aspect-ratio: 1;

  /* A workaround for the photo clipping off part of the watch face in older Safari  */
  transform: translateZ(100px);
}

.Photo-gallery-section {
  .Photo-image {
    mix-blend-mode: screen;
    z-index: 1;
  }

  .Photo-container:nth-child(1) {
    .Photo-image {
      content: url(../../assets/imgs_1200px/style_img_male_1.jpg);
    }

    .Watch-face-placeholder {
      --wfDataColor: rgb(69, 213, 141);

      transform:
        translate(10.5%, 3.2%)
        rotate3d(1, 0, 0, 33deg)
        rotate3d(0, 1, 0, -1deg)
        rotate(176deg)
        scale(0.142);
    }

    .Photo-container-internal {
      /* Formula: tx = (wx2 * scale) / (scale - 1) + cx */
      transform: scale(1);
      transform-origin:
        calc((10.5% * 2.5) / (2.5 - 1) + 50%)
        calc((3.2% * 2.5) / (2.5 - 1) + 50%);
    }
  }

  .Photo-container:nth-child(2) {
    .Photo-image {
      content: url(../../assets/imgs_1200px/style_girl_img_4_edited.jpg);
    }

    .Watch-face-placeholder {
      --wfDataColor: #d76b2d;
      --wfAccentColor: #99c8d8;

      transform:
        translate(6.05%, 1.95%)
        rotate3d(1, 0, 0, 9deg)
        rotate3d(0, 1, 0, 6deg)
        rotate(219deg)
        scale(0.082);
    }

    .Photo-container-internal {
      /* Formula: tx = (wx2 * scale) / (scale - 1) + cx */
      transform-origin:
        calc((6.05% * 3.5) / (3.5 - 1) + 50%)
        calc((1.95% * 3.5) / (3.5 - 1) + 50%);
    }
  }

  .Photo-container:nth-child(3) {
    .Photo-image {
      content: url(../../assets/imgs_1200px/style_girl_img_3_edited.jpg);
    }

    .Watch-face-placeholder {
      --wfDataColor: #00b3ff;
      --wfAccentColor: #ffc907;

      transform:
        translate(16%, -13.9%)
        rotate3d(1, 0, 0, 33deg)
        rotate(115deg)
        scale(0.135);
    }

    .Photo-container-internal {
      /* Formula: tx = (wx2 * scale) / (scale - 1) + cx */
      transform-origin:
        calc((16% * 2.5) / (2.5 - 1) + 50%)
        calc((-13.9% * 2.5) / (2.5 - 1) + 50%);
    }
  }

  .Photo-container:nth-child(4) {
    .Photo-image {
      content: url(../../assets/imgs_1200px/style_img_male_2_edited.jpg);
    }

    .Watch-face-placeholder {
      --wfTimeColor: #000;
      --wfBgColor: #fff;
      --wfTimeOverBgBlend66Color: #555;
      --wfTimeOverBgBlend33Color: #aaa;

      --wfDataColor: #555;
      --wfAccentColor: #aaa;

      transform:
        translate(3.75%, -9.29%)
        rotate3d(1, 0, 0, 45deg)
        rotate3d(0, 1, 0, -10deg)
        rotate(142deg)
        scale(0.065);
    }

    .Photo-container-internal {
      /* Formula: tx = (wx2 * scale) / (scale - 1) + cx */
      transform-origin:
        calc((3.75% * 3.5) / (3.5 - 1) + 50%)
        calc((-9.29% * 3.5) / (3.5 - 1) + 50%);
    }
  }
}