.Seconds-section {
  .toggle {
    display: flex;
    /* flex-flow: row nowrap; */
    /* flex-direction: row; */

    /* justify-self: center; */
    /* align-items: center; */
    /* justify-content: center; */
    width: fit-content;
    margin-left:  auto;
    margin-right:  auto;

    align-items: stretch;

    gap: 0;

    box-shadow: 0 0 7px #0003;
    border-radius: 600px;
    
    .checkbox {
      margin: 0;
      width: 0px;
      height: 0px;
      appearance: none;
      -webkit-appearance: none;
    }

    .label-progress {
      z-index: -1;
      position: absolute;
      width: 0%;
      background-color: var(--activeColor);
    }

    .next {
      width: 0;
      position: relative;
      align-content: center;

      .next-icon {
        position: absolute;
        left: 10px;
        top: 50%;
        width: 15px;
        transform: translateY(-50%);
        border-radius: 30px;
        background-color: var(--activeColor);
        padding: 10px;
        box-shadow: 0 0 20px #0004;
        z-index: 100;

        content: url(../../assets/imgs_128px/fast-forward.png);
      }
    }

    .checkbox:checked + label + .checkbox + label {
      border-left: none;
    }

    .checkbox + label {
      margin: 0;
      padding: .5rem 1rem;
      box-sizing: border-box;

      display: flex;
      align-items: center;

      overflow: clip;
      position: relative;
      z-index: 0;

      border-left: solid 1px #DDD;
      background-color: #FFF;
      /* transition: background-color 0.5s ease, box-shadow 0.3s ease;
      will-change: background-color, box-shadow; */
      -webkit-tap-highlight-color: transparent;

      &:first-of-type {
        border-radius: 600px 0 0 600px;
        border-right: none;
        padding-left: 1em;
      }
      
      &:last-of-type {
        border-radius: 0 600px 600px 0;
        padding-right: 1em;
      }
    }

    /* Not entire sure if this helps, but I'm trying to work around the flickering on fast scroll */
    .checkbox:not(checked) + label {
      transition: background-color 0.5s ease, box-shadow 0.3s ease;
      will-change: background-color, box-shadow;
    }

    .checkbox:checked + label {
      background-color: var(--activeColor);
      border-right: none;
    }
  }
}