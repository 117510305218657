/* @font-face {
  font-family: 'Time-font';
  /* src: url(../../assets/fonts/SairaCondensed-SemiBold.ttf) format('truetype'); * /
  /* src: url('https://fonts.googleapis.com/css2?family=Saira+Condensed:ital,wght@0,600') format('woff2'), 
       url('../../assets/fonts/SairaCondensed-SemiBold.ttf') format('truetype'); * /
  src: url('https://fonts.googleapis.com/css2?family=Saira+Condensed:ital,wght@0,600') format('woff2');
} */

/* Local fallback in case Google Fonts fails */
@font-face {
  font-family: 'Saira Condensed';
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/SairaCondensed-SemiBold.ttf') format('truetype');
}

/* Local fallback in case Google Fonts fails */
@font-face {
  font-family: 'Saira Extra Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/SairaExtraCondensed-Light.ttf') format('truetype');
}

/* @font-face {
  font-family: 'Seconds-font';
  src: url(../../assets/fonts/SairaExtraCondensed-Light.ttf) format('truetype');
} */

@font-face {
    font-family: 'Data-font';
    src: url(../../assets/fonts/Roboto_Condensed_400.woff2) format('truetype');
    unicode-range:
      U+0000-007F,      /* Basic Latin */
      U+0080-00FF,      /* Latin-1 Supplement */
      U+0100-017F,      /* Latin Extended-A */
      U+0180-024F,      /* Latin Extended-B */
      U+0250-02AF,      /* Latin Extended Additional */
      U+2C60-2C7F,      /* Latin Extended-C */
      U+1D00-1D7F,      /* IPA Extensions */
      U+0400-04FF,      /* Cyrillic */
      U+0500-052F,      /* Cyrillic Supplement */
      U+2DE0-2DFF,      /* Cyrillic Extended-A */
      U+A640-A69F,      /* Cyrillic Extended-B */
      U+1C80-1C8F,      /* Cyrillic Extended-C */
      U+0370-03FF,      /* Greek */
      U+1F00-1FFF;      /* Greek Extended */
}


@font-face {
  font-family: 'Data-font';
  src: url(../../assets/fonts/NotoSansArabic_Condensed_400.woff2) format('truetype');
  unicode-range: U+0600-06FF; /* Arabic */
}

@font-face {
  font-family: 'Data-font';
  src: url(../../assets/fonts/NotoSansThai_Condensed_400.woff2) format('truetype');
  unicode-range: U+0E00-0E7F; /* Thai characters */
}

@font-face {
  font-family: 'Data-font';
  src: url(../../assets/fonts/NotoSansKR-VF.ttf) format('truetype');
  unicode-range: U+AC00-D7AF, U+1100-11FF, U+3130-318F; /* Korean characters */
  size-adjust: 92%;
}

@font-face {
  font-family: 'Data-narrow-font';
  /* TODO: use a woff2 from Google Fonts */
  src: url(../../assets/fonts/NotoSans-ExtraCondensedMedium.ttf) format('truetype');
}

.Time-text {
  /* font: 30px 'Time-font', sans-serif; */
  font-family: 'Saira Condensed', sans-serif;
  font-weight: 600;
  font-size: 32cqw;
  line-height: 30cqw;
  text-anchor: middle;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2.08cqw;
}

.Neu-pro-am-pm {
  font-size: 7.3cqw;
  font-family: "Saira", sans-serif;
  font-weight: 600;
  font-style: normal;
  line-height: 1;

  color: var(--wfDataColor, var(--color-rose));
  position: absolute;
  right: 0;
  bottom: 3cqw;
}

.Neu-pro-am-pm-stroke {
  -webkit-text-stroke: 2.5cqw black;
}

.Seconds-text {
  /* font: 30px 'Seconds-font', sans-serif; */
  font-family: 'Saira Extra Condensed', sans-serif;
  font-weight: 300;
  font-size: 18cqw;
  line-height: 18cqw;
}

.Time-delimiter {
  margin-top: 1cqw;
  width: 1.4cqw;
  height: 21.5cqw;
}

.Data-text {
  font: 1.7rem "Data-font", sans-serif;
  font-size: 9.5cqw;
  line-height: 9.5cqw;
  white-space: nowrap;
}

.Data-narrow-font {
  font: 1.7rem "Data-narrow-font", sans-serif;
  font-size: 9.5cqw;
  line-height: 9.5cqw;
  margin-top: -0.5cqw;
}

.Data-title-short {
  font: 1.7rem "Data-font", sans-serif;
  font-size: 8.2cqw;
  line-height: 8.2cqw;
  white-space: nowrap;
  margin-top: 1cqw;
  margin-bottom: 1.5cqw;
}

.Data-title-long {
  font: 1.7rem "Data-font", sans-serif;
  font-size: 9.5cqw;
  line-height: 9.5cqw;
  white-space: nowrap;
}

.Data-icon {
  --dataIconSize: 10cqw;
  width: var(--dataIconSize);
  height: var(--dataIconSize);
  mask-size: var(--dataIconSize) var(--dataIconSize);
}

.Data-icon-vertical {
  margin-top: -0cqw;
  margin-bottom: 0.5cqw;
}

.Data-icon-battery_days, .Data-icon-battery_percentage {
  --iconH: 6cqw;
  --iconW: calc(var(--iconH) * 192/108);

  width: var(--iconW);
  height: var(--iconH);
  mask-size: var(--iconW) var(--iconH);
  margin-top: 1.8cqw;
  margin-bottom: 2.5cqw;
}

.Data-fields-heartRate-bgColor {
  background-color: #F5A;
}

.Data-fields-calories-bgColor {
  background-color: #FA0;
}

.Data-fields-altitude-bgColor {
  background-color: #5af;
}

.Data-fields-steps-bgColor {
  background-color: #af5;
}

.Watch-face-bg-fill {fill: var(--wfBgColor, #000);}
.Watch-face-bg-bgColor {background-color: var(--wfBgColor, #000);}

.Watch-face-time-color {color: var(--wfTimeColor, #fff);}
.Watch-face-time-stroke {stroke: var(--wfTimeColor, #fff);}
.Watch-face-time-fill {fill: var(--wfTimeColor, #fff);}
.Watch-face-time-stroke-blend-0_67 {stroke: var(--wfTimeOverBgBlend66Color, #aaa);}
.Watch-face-time-fill-blend-0_67 {fill: var(--wfTimeOverBgBlend66Color, #aaa);}
.Watch-face-time-stroke-blend-0_33 {stroke: var(--wfTimeOverBgBlend33Color, #555);}
.Watch-face-time-fill-blend-0_33 {fill: var(--wfTimeOverBgBlend66Color, #555);}

.Watch-face-accent-stroke {stroke: var(--wfAccentColor, #555);}
.Watch-face-accent-fill {fill: var(--wfAccentColor, #555);}
.Watch-face-accent-bgColor {background-color: var(--wfAccentColor, #555);}

.Neu-Pro-data-title-color {
  color: var(--color);
}

.Neu-Pro-data-icon-color {
  background-color: var(--color);
}

.Neu-Pro-data-title-month_monthday { --color: var(--wfDataColor, var(--color-blue)); }
.Neu-Pro-data-title-weekday_monthday { --color: var(--wfDataColor, var(--color-blue)); }
.Neu-Pro-data-title-battery_days { --color: var(--wfDataColor, var(--color-green)); }
.Neu-Pro-data-title-battery_percentage { --color: var(--wfDataColor, var(--color-green)); }
.Neu-Pro-data-title-solar_input { --color: var(--wfDataColor, var(--color-yellow)); }
.Neu-Pro-data-title-heart_rate { --color: var(--wfDataColor, var(--color-red)); }
.Neu-Pro-data-title-steps { --color: var(--wfDataColor, var(--color-magenta)); }
.Neu-Pro-data-title-distance_walked { --color: var(--wfDataColor, var(--color-acid-green)); }
.Neu-Pro-data-title-calories { --color: var(--wfDataColor, var(--color-orange)); }
.Neu-Pro-data-title-floors_climbed { --color: var(--wfDataColor, var(--color-magenta)); }
.Neu-Pro-data-title-current_temperature { --color: var(--wfDataColor, var(--color-orange)); }
.Neu-Pro-data-title-min_max_temperature { --color: var(--wfDataColor, var(--color-orange)); }
.Neu-Pro-data-title-sunrise_sunset { --color: var(--wfDataColor, var(--color-orange)); }
.Neu-Pro-data-title-altitude { --color: var(--wfDataColor, var(--color-turquoise)); }
.Neu-Pro-data-title-notification_count { --color: var(--wfDataColor, var(--color-yellow)); }
.Neu-Pro-data-title-calendar_event { --color: var(--wfDataColor, var(--color-lt-grey)); }
.Neu-Pro-data-title-body_battery { --color: var(--wfDataColor, var(--color-acid-green)); }
.Neu-Pro-data-title-stress { --color: var(--wfDataColor, var(--color-blue)); }
.Neu-Pro-data-title-intensity_minutes { --color: var(--wfDataColor, var(--color-magenta)); }
.Neu-Pro-data-title-recovery_time { --color: var(--wfDataColor, var(--color-blue)); }
.Neu-Pro-data-title-weekly_run_distance { --color: var(--wfDataColor, var(--color-acid-green)); }
.Neu-Pro-data-title-weekly_bike_distance { --color: var(--wfDataColor, var(--color-acid-green)); }
.Neu-Pro-data-title-vo2_max_run { --color: var(--wfDataColor, var(--color-blue)); }
.Neu-Pro-data-title-vo2_max_bike { --color: var(--wfDataColor, var(--color-blue)); }
.Neu-Pro-data-title-pulse_ox { --color: var(--wfDataColor, var(--color-blue)); }
