.connect-iq-nav-dialog-positioning {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 1fr auto 1fr;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas:
    ". ...... ."
    ". dialog ."
    ". ...... .";

    background-color: rgba(0, 0, 0, 0.5);
  }

.connect-iq-nav-dialog-overlay {
  z-index: -1;
  pointer-events: all;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black with 50% opacity */
}

.connect-iq-nav-dialog-positioning.open {
  display: grid;
}

.connect-iq-nav-dialog-positioning:not(.open) {
  display: none;
}

.connect-iq-nav-dialog {
  grid-area: dialog;

  overflow: hidden; 
  max-width: 30rem;
  text-align: left;
  
  padding: 1.5rem;

  margin: 1rem;

  background-color: white;
  border-color: var(--activeColor);
  border-style: solid;
  border-width: 1px;
  border-radius: 25px;
  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1);
}

.connect-iq-nav-dialog h3 {
  font: 1.5rem 'SairaCondensed', sans-serif;
  /* line-height: 0.998 is a workaround to prevent Webpack from merging styles for h1 and h3 */
  line-height: 0.998;
  margin-top: 0em;
  /* margin-bottom: 0em; */

  text-align: center;
}

.connect-iq-nav-dialog p {
  margin-top: 0.7em;
  margin-bottom: 0.7em;
}

.connect-iq-nav-dialog-buttons {
  padding: 0;
  margin-top: 1.5em;
  display: flex;
  flex-wrap: wrap; 
  align-content: stretch;
  align-items: stretch;
  justify-content:center;
  gap: 0.5rem
}

.connect-iq-nav-dialog-buttons button {
  margin: 0;
}

