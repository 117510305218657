@font-face {
  /* Only used in the watch face */
  /* TODO: deal with this later */
  font-family: 'SairaCondensed-SemiBold';
  src: url(./assets/fonts/SairaCondensed-SemiBold.ttf) format('truetype');
}

@font-face {
  /* TODO: rename font family */
  font-family: 'SairaCondensed';
  src: url(./assets/fonts/SairaCondensed-SemiBold.ttf) format('truetype');
  font-weight: 700; /* Bold weight */
}

@font-face {
  font-family: 'SairaCondensed';
  src: url(./assets/fonts/SairaCondensed-Regular.ttf) format('truetype');
  font-weight: 400; /* Regular weight */
}

@font-face {
  font-family: 'SairaExtraCondensed-Light';
  src: url(./assets/fonts/SairaExtraCondensed-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoCondensed';
  src: url(./assets/fonts/RobotoCondensed-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoMedium';
  src: url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoLight';
  src: url(./assets/fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoItalic';
  src: url(./assets/fonts/Roboto-Italic.ttf) format('truetype');
}

@font-face {
  font-family: 'RobotoLight';
  src: url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
  font-weight: bold;
}

:root {
  /* --activeColor: #0fa; */
  --activeColor: rgb(156, 255, 117);
  --activeColorBlend: rgb(156, 255, 117, 0.5);

  --bg: #fff;
  --mainText: #1a202c;
  --header: #fff;
  --headerBorder: #555;
  --button: var(--activeColor);

  --color-magenta: #f5f;
  --color-rose: #f5a;
  --color-red: #f05;
  --color-orange: #fa0;
  --color-yellow: #ff5;
  --color-acid-green: #af5;
  --color-green: #5f5;
  --color-turquoise: #5fa;
  --color-blue: #5af;
  --color-black: #000;
  --color-white: #fff;
  --color-dk-grey: #555;
  --color-lt-grey: #aaa;

  /* --header-height: 220px; */
  --header-height: 4rem;
  --footer-height: 4rem;
  --section-padding: 2rem;

  --body-height: calc(100vh - var(--header-height));
}

.noscroll {
  overflow: hidden;
}

body {
  background-color: var(--bg);
  color: var(--mainText);
}

/* So that individual elements like photo gallery can ignore it and stay full-width */
section > * {
  margin-left: var(--section-padding);
  margin-right: var(--section-padding);
}

.App {
  text-align: center;
	overflow-x: clip;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

header {
  position: fixed;
  top: 0;
  width: calc(100% - 2 * var(--section-padding));
  height: var(--header-height);
  z-index: 1;

  padding-top: 0;
  padding-bottom: 0;
  padding-left: var(--section-padding);
  padding-right: var(--section-padding);
  margin-top: 0;
  margin-bottom: 0;

  font: 2.2rem 'SairaCondensed', sans-serif;
  background-color: var(--header);

  border-bottom: 1px inset var(--headerBorder);

  display: flex; 
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;

  span {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  button {
    margin: 0;
  }

  a:has(button) {
    all: unset;
    display: inline-flex;
  }
}

.Abs-fill-parent {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Watch-container {
  aspect-ratio: 0.9;
  pointer-events: none;
  margin-left: 40px;
  margin-right: 40px;
}

.Watch-face-container {
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Watch-image {
  pointer-events: none;
  background-image: url(assets/imgs_1200px/d2airx10_2.jpg);
  background-position: center;
  background-size: cover;
  border-radius: 40px;
}

.Watch-image2 {
  pointer-events: none;
  background-image: url(assets/imgs_1200px/venu2_2.jpg);
  background-position: center;
  transform: translate(0.4%, -0.2%) scale(0.99);
  background-size: cover;
  border-radius: 40px;
}

.Inline-size-container {
  container: my-container / inline-size;
}

.Watch-face {
  container: watch-face / inline-size;
  width: 100%;
  aspect-ratio: 1;
  pointer-events: none;
  border-radius: 10000px;
  display: flex;
}

button {
  font: 1.2rem "RobotoMedium", Fallback, sans-serif;
  /* color: #fff; */
  border-radius: 10px;
  /* border-radius: 1000px; */
  border: none;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: var(--button);
  color: #000;
  margin: 0.5rem;
  /* font: 1.7rem "RobotoCondensed", Fallback, sans-serif; */
}

button:disabled, button[disabled] {
  background-color: #ddd;
}

.button-secondary {
  background-color: white;
  color: #000;
  border-color: var(--button);
  border-style: solid;
  border-width: 2px;
}

button:active {
  /* background-color: #3e8e41; */
  /* box-shadow: 0 5px #666; */
  transform: translateY(2px);
}

button:active:disabled, button:active[disabled] {
  transform: unset;
  outline: 2px solid var(--color-red);
}

button small {
  font: 0.8rem "RobotoMedium", Fallback, sans-serif;
  /* font: 1.7rem "SairaCondensed-Regular", Fallback, sans-serif; */
}

h1 {
  font: 3rem 'SairaCondensed', sans-serif;
  line-height: 1;
  margin-bottom: 0;
  margin-top: 1rem;
}

h2 {
  font: 2rem 'SairaCondensed-SemiBold', sans-serif;
  /* line-height: 0.999 is a workaround to prevent Webpack from merging styles for h1 and h2 */
  line-height: 0.999;
  margin-top: 1rem;
}

h2:has(+ h3) {
  margin-bottom: 0;
}

h3 {
  font: 1.5rem 'SairaCondensed', sans-serif;
  /* line-height: 0.998 is a workaround to prevent Webpack from merging styles for h1 and h3 */
  line-height: 0.998;
  margin-top: 0.25em;
}

select {
  all: unset;

  font: inherit;
  text-align: left;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  display: inline-block;

  padding-left: 0.2em;
  padding-right: 0.2em;
  padding-top: 0.1em;
  padding-bottom: 0.1em;

  border: 0.5px solid #ccc;
  border-radius: 8px;
  color: black;
  cursor: pointer;

  --arrow-bg: white;
  --select-bg: white;

  --padding-lr: 0.4em;
  --padding-tb: 0.2em;
  --right-square-w: calc(var(--padding-lr) * 2 + 1em);
  --right-square-h: calc(var(--padding-tp) * 2 + 1em);

  padding: var(--padding-tb) calc(var(--padding-lr) + var(--right-square-w)) var(--padding-tb) var(--padding-lr);

  --arrow-icon: url(assets/imgs_128px/drop_down_icon.png);
  --icon-size: 0.9em;
  background: var(--arrow-icon) no-repeat right calc(var(--right-square-w) / 2 - var(--icon-size) / 2) center / var(--icon-size),
    linear-gradient(to left, var(--arrow-bg) var(--right-square-w), var(--select-bg) var(--right-square-w));
}

select.required {
  border: 2px solid var(--button);
}

.country-selector-paragraph {
  margin-top: 1rem;
  text-align: center;
}

.price-disclaimer {
  font: 0.8rem 'RobotoLight', sans-serif;
}

p {
  font: 1.2rem 'RobotoLight', sans-serif;
  text-align: left;
  margin-bottom: 1.2rem;
}

table {
  font: 1.2rem 'RobotoLight', sans-serif;
  text-align: left;
  margin-bottom: 1.2rem;
}

.section-text-cell > p:first-child {
  margin-top: 0;
}

p.download-paragraph {
  text-align: center;
}

ul, ol {
  font: 1.2rem 'RobotoLight', sans-serif;
  margin-top: 0px;
  padding-left: 2.5rem;
  text-align: left;
}

ul li {
  margin-bottom: 0.3em; /* Adjust the value to your needs */
}

ol li {
  margin-bottom: 0.3em; /* Adjust the value to your needs */
}

.expandable-icon {
  --icon-size: 1rem;

  display: inline-block;
  background-color: var(--color-blue);
  mask-image: url(assets/imgs_128px/caret.png);
  mask-size: var(--icon-size) var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
  rotate: -90deg;
  margin-left: 0.5rem;
}

.expandable-icon.open {
  rotate: 0deg;
}

.Scroll-to-learn-more-container {
  position: fixed;
  background-color: rgb(255, 255, 255);
  bottom: 0;
  width: 100%;
  opacity: 1;
  z-index: 100;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
  padding-top: 0.3rem;
  padding-bottom: 0.8rem;
  text-align: center;
}

.Scroll-to-learn-more {
  display: inline-flex;
  flex-direction: column;

  color: var(--color-lt-grey);
  gap: 0.3rem;

  align-items: center;

  > p {
    margin-bottom: 0.3rem;
  }
}

.Scroll-to-learn-more-caret {
  --icon-size: 0.8rem;

  background-color: var(--color-lt-grey);
  mask-image: url(assets/imgs_128px/caret.png);
  mask-size: var(--icon-size) var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
}

.custom-details-button-div {
  text-align: left;
}

.custom-details {
  background-color: unset;
}

.custom-details-toggle-button {
  background-color: unset;
  font: 1.4rem 'SairaCondensed', sans-serif;
  color:var(--color-blue);
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  align-items: center;
  text-align: left;
}

.custom-details-fold:not(.open) {
  max-height: 0;
  overflow: clip;
}

.custom-details-fold.open {
  max-height: none;
}

/* Overrides for desktop layout: */
@media (min-aspect-ratio: 1) {

  /* Phones in landscape orientation */
  @media (max-height: 500px) {
    /* h1 {
      background-color: red;
    } */

    h1 {
      font: 3rem 'SairaCondensed', sans-serif;
      line-height: 1;

      margin-top: 0.4rem;
    }
    
    h2 {
      font: 2rem 'SairaCondensed-SemiBold', sans-serif;
      /* line-height: 0.999 is a workaround to prevent Webpack from merging styles for h1 and h2 */
      line-height: 0.999;

      margin-top: 0.4rem;
    }
    
    h3 {
      font: 1.5rem 'SairaCondensed', sans-serif;
      /* line-height: 0.998 is a workaround to prevent Webpack from merging styles for h1 and h3 */
      line-height: 0.998;

      margin-bottom: 1.2rem;
    }
  }

  /* Computers and tablets in landscape orientation */
  @media (min-height: 500px) {
    h1 {
      font: 5rem 'SairaCondensed', sans-serif;
      line-height: 1;

      margin-top: 0.4rem;
      margin-bottom: 0.5rem;
    }

    h2 {
      font: 4rem 'SairaCondensed-SemiBold', sans-serif;
      /* line-height: 0.999 is a workaround to prevent Webpack from merging styles for h1 and h2 */
      line-height: 0.999;

      margin-top: 0.4rem;
    }

    h3 {
      font: 3rem 'SairaCondensed', sans-serif;
      /* line-height: 0.998 is a workaround to prevent Webpack from merging styles for h1 and h3 */
      line-height: 0.998;

      margin-top: 0.5rem;
      margin-bottom: 1.6rem;
    }
  }

  h1 {
    text-align: center;
  }
  
  h2 {
    text-align: center;
  }
  
  h3 {
    text-align: center;
  }
}

.iub__us-widget:last-of-type {
  /* margin-top: 100px; */
  justify-content: center;
}

.iub__us-widget__wrapper:last-of-type {
  border: 0.7px solid #aaa;
}

.iub__us-widget__wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.iub__us-widget__link {
  text-align: center;      /* Ensure the link text is centered */
}

.iub__us-widget__link--privacy-choices:last-of-type::after {
  content: url("data:image/svg+xml,%3Csvg%20width='40'%20height='18'%20viewBox='0%200%2040%2018'%20fill='none'%20xmlns='http://www.w3.org/2000/svg'%3E%3Crect%20x='0.5'%20y='0.5'%20width='39'%20height='17'%20rx='8.5'%20fill='white'%20stroke='%23009664'%2F%3E%3Cpath%20d='M22.5%200H31C35.9706%200%2040%204.02944%2040%209C40%2013.9706%2035.9706%2018%2031%2018H18L22.5%200Z'%20fill='%23009664'%2F%3E%3Cpath%20d='M8%209.5L10.5%2012L16.5%206'%20stroke='%23009664'%20stroke-width='1.5'%20stroke-linecap='round'%20stroke-linejoin='round'%2F%3E%3Cpath%20d='M25.5%206L31.5%2012'%20stroke='white'%20stroke-width='1.5'%20stroke-linecap='round'%2F%3E%3Cpath%20d='M31.5%206L25.5%2012'%20stroke='white'%20stroke-width='1.5'%20stroke-linecap='round'%2F%3E%3C%2Fsvg%3E");
}

.iub__us-widget__link--privacy-choices:last-of-type {
  border-color: #aaa;
  border-width: 0.7px;
}