/* Be careful, this rule affects a lot of pages! */
.Introduce-img-wf-placeholder {
  /* opacity: 0.7; */
  transform: translate(0.3%, 2.45%) scale(0.735);
  aspect-ratio: 1;
  border-radius: 1000px;
  background-color: black;
  overflow: 'clip';
}

.Section-intro {
  --imageRightBorderWidth: 0px;

  .section-image-cell {
    position: relative;
    margin-left: 0;
    margin-right: 0;
    overflow: unset;

    z-index: -1;
    margin-top: -3rem;
    margin-bottom: -1rem;
    aspect-ratio: 0.9;
  }

  .photo-gradient-top {
    position: absolute;
    left: 0;
    right: -10px;
    top: -1px;
    height: 25%;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    z-index: 2;
  }

  .photo-gradient-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1px;
    height: 25%;
    background: linear-gradient(to top, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    z-index: 2;
  }

  .Watch-face-placeholder {
    transform:
      translate(5cqw, -0.6cqw)
      rotate3d(1, 0, 0, 10deg)
      scale(0.435);
  }
}

.forest-photo-clip {
  position: absolute;
  right: var(--imageRightBorderWidth);
  top: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.forest-photo-img {
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;

  /* The image is slightly taller just in case, so I'm scaling it */
  scale: calc(90 / 85);
  transform-origin: 100% 50%;

  mix-blend-mode: screen;
  z-index: 10;
  filter: brightness(1.2);
}

.Watch-face-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: var(--imageRightBorderWidth);
  transform: translateZ(-10rem);
  aspect-ratio: 1;
}

@media (min-aspect-ratio: 1) {
  .Section-intro {
    --imageRightBorderWidth: 1px;
  }

  /* Phones in landscape orientation */
  @media (max-height: 40rem) {
    .Section-intro.section-with-image {
      grid-template-areas:
        "image image title ."
        "image image text  .";
    }
  }

  /* Computers and tablets in landscape orientation */
  @media (min-height: 40rem) {
    .Section-intro.section-with-image {
      grid-template-areas:
        "title title title title"
        "image image text  .";
    }

    .section-image-cell {
      max-height: calc(var(--body-height) - 20vh);
    }
  }

  .fake-border-right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: var(--imageRightBorderWidth);
    background-color: #aaa;
    z-index: 1;
  }

  .Section-intro {
    .section-image-cell {
      height: 100vh;
      aspect-ratio: unset;
    }
  }
}