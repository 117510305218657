@media (max-aspect-ratio: 1) {
  .section-with-image.Section-faq {
    grid-template-rows: auto 0px 0rem 1fr;
    grid-template-areas:
      "title"
      "image"
      "."
      "text";
  }

  .Section-faq .section-image-cell {
    overflow: hidden;
    display: none;
  }
}

/* .Section-faq {
  min-height: calc(100vh - var(--header-height) - var(--footer-height));
} */