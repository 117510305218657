.Langs-section {
  --letter-size: 40cqw;
  --size: 120cqw;

  .Flying-letters {
    position: absolute;
    left: 50%;
    top: 50%;
    width: var(--size);
    height: var(--size);
    transform: translate(-50%, -50%);
    border-radius: 1000px;
    z-index: 100;
  }

  .Single-flying-letter {
    position: absolute;
    border-radius: 100px;

    color: var(--activeColor);
    font-size: calc(var(--letter-size) * 0.4);

    width: var(--letter-size);
    height: var(--letter-size);

    transform:
      translate(-50%, -50%)
      translate(calc(var(--size) / 2), calc(var(--size) / 2))
      rotate(calc(var(--num) / 7 * 3.14rad * 2))
      translate(calc(var(--size) / 2))
      rotate(var(--randomAngle));

      .inner {
        display: flex;
        justify-content: center;
        align-items: center;
    
        animation-name: Languages-single-letter-spin-anim;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    
        text-shadow: 0px 0px 10px var(--activeColorBlend);
      }
  }

  .Single-flying-letter:nth-child(1) {--num: 1; --randomAngle: 45deg; .inner { animation-duration: 2200ms; } }
  .Single-flying-letter:nth-child(2) {--num: 2; --randomAngle: 85deg; .inner { animation-duration: 2300ms;} }
  .Single-flying-letter:nth-child(3) {--num: 3; --randomAngle: 145deg; .inner { animation-duration: 2000ms;} }
  .Single-flying-letter:nth-child(4) {--num: 4; --randomAngle: 35deg; .inner { animation-duration: 2500ms;} }
  .Single-flying-letter:nth-child(5) {--num: 5; --randomAngle: 272deg; .inner { animation-duration: 2000ms;} }
  .Single-flying-letter:nth-child(6) {--num: 6; --randomAngle: 63deg; .inner { animation-duration: 2100ms;} }
  .Single-flying-letter:nth-child(7) {--num: 7; --randomAngle: 210deg; .inner { animation-duration: 2250ms;} }
}

@keyframes Languages-single-letter-spin-anim {
  from { rotate: 0deg; }
  to { rotate: 360deg; }
}