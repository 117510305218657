.review-stars-container {
  display: inline-flex;
  flex-direction: row;
  justify-content:space-between;

  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  gap: 0.3rem;
}

.review-star {
  --icon-size: 1.5rem;

  background-color: #FFD334;
  mask-image: url(../../assets/imgs_128px/star.png);
  mask-size: var(--icon-size) var(--icon-size);
  width: var(--icon-size);
  height: var(--icon-size);
  display: inline-block;
}

.User-review-text {
  font: 1.1rem 'RobotoItalic', sans-serif;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
}

.User-review-author {
  font: 1rem 'RobotoLight', sans-serif;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: center;
}

.User-review-quote-icon {
  --icon-width: 1.8rem;
  --icon-height: calc(var(--icon-width) * 0.8);

  position: absolute;
  background-color: #ccc;
  mask-image: url(../../assets/imgs_128px/quote.png);
  mask-size: var(--icon-width) var(--icon-height);
  width: var(--icon-width);
  height: var(--icon-height);
  left: var(--padding-sides);
  top: calc(var(--icon-width) * -0.37);
}

.User-review-outer-box {
  display: block;
}

.User-review-box {
  margin-top: 1rem;

  display: inline-block;

  position: relative;

  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 2.2rem;
  padding-right: 2.2rem;

  border-style: solid;
  border-width: 0.5px;
  border-color: #AAA;

  box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 32px;
}
