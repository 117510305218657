.section-with-image {
  display: grid;
  grid-template-rows: auto 1fr 1rem auto;
  grid-template-areas:
    "title"
    "image"
    "."
    "text";
}

.section-image-cell {
  grid-area: image;

  /* TODO: make sure overflow-clip-margin is always enough for the shadow */
  overflow-clip-margin: 40px;
}


.section-title-cell {
  grid-area: title;
}

.section-text-cell {
  grid-area: text;
}

/* .section-title-cell { background-color: violet; }
.section-text-cell { background-color: purple; }
.section-image-cell { background-color: orange; } */

@media (min-aspect-ratio: 1) {

  /* Phones in landscape orientation */
  @media (max-height: 40rem) {
    .section-with-image {
      min-height: var(--body-height);
  
      grid-template-columns: 1fr min(min(40vw, 90vh), 30rem) min(min(40vw, 90vh), 30rem) 1fr;
      grid-template-areas:
        ". image title ."
        ". image text  .";
      
      row-gap: 0;
      column-gap: 2em;
    }

    .section-image-cell {
      max-height: var(--body-height);
    }
  }

  /* Computers and tablets in landscape orientation */
  @media (min-height: 40rem) {
      .section-with-image {
        min-height: var(--body-height);
    
        grid-template-columns: 1fr min(min(40vw, 90vh), 30em) min(min(40vw, 90vh), 30em) 1fr;
        grid-template-areas:
          "title title title title"
          ". image text  .";
        
        row-gap: 0;
        column-gap: 2em;
      }
      
      .section-image-cell {
        max-height: calc(var(--body-height) - 40vh);
      }  
  }

  .section-image-cell {
    margin: 0;

    position: relative;
  }

  .section-image-noclip {
    /* This is for centering image vertically */
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%); */

    max-height: 100%;
  }

  .section-image-clip {
    /* This is for centering image vertically */
    /* position: absolute;
    top: 50%;
    transform: translateY(-50%); */

    max-height: 100%;

    border-radius: 40px;
    border: solid;
    border-width: 2px;
    border-color: #ddd;
    box-shadow: 1px 4px 6px rgba(0, 0, 0, 0.1);

    margin-left: auto;
    margin-right: 0;
  }

  .section-title-cell {
    margin: 0;
  }

  .section-text-cell {
    margin: 0;
  }
}