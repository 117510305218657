.Heart-rate-app {
  background-image: url(../../assets/imgs_1200px/heart_rate_app.png);
  background-size: 100% 100%;
  aspect-ratio: 1;
  width: 100%;
  border-radius: 1000px;
  overflow: clip;
  opacity: 0;
  scale: 1.5;
}


.Long-press-section .Press-location {
  translate: -3cqw 30cqw;
}

.Long-press-section .Hand {
  background-image: url(../../assets/imgs_256px/hand.png);
  background-size: 100% 100%;
  
  translate: 12cqw 16.5cqw;
  scale: 0.5;
}

.Long-press-section .Clock {
  width: 30%;
  height: 30%;
  left: 60%;
  top: 40%;
  opacity: 0;
  background-color: white;
  border: solid;
  border-width: 3cqw;
  box-shadow: 0 0 20px #0008;
  border-radius: 1000px;
}

.Long-press-section .Clock .Center {
  width: 16%;
  height: 16%;
  left: 42%;
  top: 42%;
  background-color: black;
  transform-origin: 50% 100%;
  border-radius: 500px;
}

.Long-press-section .Clock .Minutes {
  width: 10%;
  height: 45%;
  left: 45%;
  top: 5%;
  background-color: black;
  transform-origin: 50% 100%;
  border-radius: 500px;
}

.Long-press-section .Clock .Hours {
  width: 10%;
  height: 30%;
  left: 45%;
  top: 20%;
  background-color: black;
  transform-origin: 50% 100%;
  rotate: 60deg;
  border-radius: 500px;
}

.Long-press-section .Press-ripple {
  mask-image: url(../../assets/imgs_256px/ripple.png);
  mask-size: 100% 100%;
  background-color: var(--activeColor);
  scale: 0;
  opacity: 0;
}

.App.no-auto-animation .Long-press-section .Animated-element {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 4000ms;
}

.App.no-auto-animation .Long-press-section .Hand {
  animation-name: Long-press-hand-move;
}

.App.no-auto-animation .Long-press-section .Hand-zoom {
  animation-name: Long-press-hand-zoom;
}

.App.no-auto-animation .Long-press-section .Clock {
  animation-name: Long-press-clock-opacity;
}

.App.no-auto-animation .Long-press-section .Minutes {
  animation-name: Long-press-clock-minutes;
}

.App.no-auto-animation .Long-press-section .Hours {
  animation-name: Long-press-clock-hours;
}

.App.no-auto-animation .Long-press-section .Press-ripple {
  animation-name: Long-press-ripple;
}

/* .Watch-face doesn't have the .Animated-element co-class, so I'm specifying all properties explicitly */
.App.no-auto-animation .Long-press-section .Watch-face {
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: Long-press-watch-face;
  animation-duration: 4000ms;
}

.App.no-auto-animation .Long-press-section .Heart-rate-app {
  animation-name: Long-press-heart-rate-app;
}

/* TODO: can I put these on a single timeline somehow? E.g. animate user variables? */

@keyframes Long-press-hand-move {
  from { transform: translate(20%, 30%); }
  4% { transform: translate(18%, 27%); }
  6.5% { transform: translate(16%, 24%); }
  15% { transform: translate(0%, 0%); }
  55% { transform: translate(0%, 0%); }
  75% { transform: translate(20%, 30%); }
  to { transform: translate(20%, 30%); }
}

@keyframes Long-press-hand-zoom {
  15% { transform: scale(1); }
  25% { transform: scale(0.8); }
  50% { transform: scale(0.8); }
  60% { transform: scale(1); }
}

@keyframes Long-press-clock-opacity {
  25% { opacity: 0; }
  28% { opacity: 1; }
  40% { opacity: 1; }
  55% { opacity: 0; }
}

@keyframes Long-press-clock-minutes {
  25% { rotate: 0deg; }
  30% { rotate: 0deg; }
  40% { rotate: 360deg; }
  55% { rotate: 360deg; }
}

@keyframes Long-press-clock-hours {
  25% { rotate: 60deg; }
  30% { rotate: 60deg; }
  40% { rotate: 90deg; }
  55% { rotate: 90deg; }
}

@keyframes Long-press-ripple {
  from { opacity: 0; scale: 0; }
  40% { opacity: 0; scale: 0; }
  41% { opacity: 1; scale: 0;}
  55% { opacity: 0; scale: 1;}
  to { opacity: 0; scale: 0; }
}

@keyframes Long-press-watch-face {
  from { opacity: 1; scale: 1; }
  45% { opacity: 1; scale: 1; }
  50% { opacity: 0; scale: 0.5;}
  95% { opacity: 0; scale: 0.5;}
  to { opacity: 1; scale: 1; }
}

@keyframes Long-press-heart-rate-app {
  45% { opacity: 0; scale: 1.5; }
  55% { opacity: 1; scale: 1;}
  95% { opacity: 1; scale: 1;}
  to { opacity: 0; scale: 1.5; }
}
